import React from "react"
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import { ArrowRight, Mail } from '@geist-ui/react-icons'

import { StaticImage } from "gatsby-plugin-image";

import Kickboxing from '../../static/assets/Kickboxing.png'
import Mountain from '../../static/assets/Mountain.png'
import Broadway from '../../static/assets/Music.png'
import Dog from '../../static/assets/Dog.png'
import Barn from '../../static/assets/Barn.png'

const About = () => {
  return <>
    <Helmet>
      <title>Nicole Schultz Ninteau - Enriched</title>
      <meta name="description" content="Nicole Ninteau - Enriched" />
    </Helmet>
    <div className="resume-page">
    <div className="resume-column">
    <div className="top-section">
    <h1>Nicole Schultz Ninteau</h1>
    <h6 className="top">P<span style={{textTransform:'lowercase'}}>h</span>D, MPH, CPT</h6>
    <div className="about-photo">
      <StaticImage
              src='../../static/assets/Nicole.png'
              alt="Nicole Ninteau"
            />
    </div>
    </div>
    <h4>Contact</h4>
    <div className="email">
      <Mail /><a href="mailto:nicole@enriched.health">nicole@enriched.health</a>
    </div>
    <p>Nicole Schultz Ninteau, PhD, MPH, CPT, embodies a rare combination of basic science and applied skills. 
            Studying Human Biochemistry and Metabolism for over 12 years, Nicole simultaneously coached clients in fitness 
            settings and taught students in academic settings. She has leveraged her deep scientific knowledge and 
            communication skills to elevate students’ learning while leading clients to weight loss and improved eating habits. 
    </p>
    <h4>Education</h4>
    <div className="timeline-item">
      <h5>2014<br />-<br />2018</h5>
      <h3>
        •
        <div className="line" />
      </h3>
      <div className="timeline-info">
      <h4><span style={{textTransform:'none'}}>PhD</span> Biochemical and Molecular Nutrition</h4>
      <h6>Tufts University Friedman School of Nutrition Science and Policy</h6>
      </div>
    </div>
    <div className="timeline-item">
      <h5>2012<br />-<br />2014</h5>
      <h3>
        •
        <div className="line second-last" />
      </h3>
      <div className="timeline-info">
       <h4>MS Biochemical and Molecular Nutrition, Specialization in Exercise Physiology</h4>
       <h6>Tufts University Friedman School of Nutrition Science and Policy</h6>
      <h4>MPH Health Communication</h4>
      <h6>Tufts University School of Medicine</h6>
     </div>
    </div>
    <div className="timeline-item">
      <h5>2005<br />-<br />2008</h5>
      <h3>
        •
        <div className="line last" />
      </h3>
      <div className="timeline-info">
      <h4>BS Biochemistry</h4>
      <h6>Southern Methodist University</h6>
      </div>
    </div>
    </div>
    <div className="resume-column">
    <div className="top-section">
    <h4 className="no-mt">Interests</h4>
    <div className="timeline-item interests">
      <img
                src={Kickboxing}
                alt="Kickboxing"
              /> 
      <h6>Kickboxing</h6>
    </div>
    <div className="timeline-item interests">
      <img
                src={Mountain}
                alt="Mountain"
              /> 
      <h6>Hiking and Skiing</h6>
    </div>
    <div className="timeline-item interests">
      <img
                src={Broadway}
                alt="Broadway"
              /> 
      <h6>Broadway</h6>
    </div>
    <div className="timeline-item interests">
      <img
                src={Dog}
                alt="Big Dogs"
              /> 
      <h6>Big Dogs</h6>
    </div>
    <div className="timeline-item interests">
      <img
                src={Barn}
                alt="New England Farmhouses"
              /> 
      <h6>New England Farmhouses</h6>
    </div>
    </div>
    <h4>Experience</h4>
    <div className="timeline-item">
      <h5>2020<br />-<br />Present</h5>
      <h3>
        •
        <div className="line" />
      </h3>
      <div className="timeline-info">
       <h4>Adjunct Professor</h4>
       <h6>Tufts University Friedman School of Nutrition Science and Policy</h6>
       <p>
      Teaching Current Controversies in Nutrition course</p>
     </div>
    </div>
    <div className="timeline-item">
      <h5>2020<br />-<br />Present</h5>
      <h3>
        •
        <div className="line" />
      </h3>
      <div className="timeline-info">
       <h4>Adjunct Professor</h4>
       <h6>The George Washington University Milken Institute of Public Health<br />
      Department of Exercise and Nutrition Science</h6>
       <p>
      Teaching Fundamentals of Nutrition Science graduate course</p>
     </div>
    </div>
    <div className="timeline-item">
      <h5>2019<br />-<br />Present</h5>
      <h3>
        •
        <div className="line" />
      </h3>
      <div className="timeline-info">
       <h4>Wellness Advisor</h4>
       <h6>Schultz Financial Group</h6>
       <p>Oversee employee wellness program, advise corporate strategy for integrating physical well-being into core values and client services, and develop nutrition and health-related content</p>
     </div>
    </div>
    <div className="timeline-item">
      <h5>2006<br />-<br />Present</h5>
      <h3>
        •
        <div className="line" />
      </h3>
      <div className="timeline-info">
       <h4>Group Fitness Instructor and Personal Trainer</h4>
     </div>
    </div>
    <div className="timeline-item">
      <h5>2015<br/>-<br />2020</h5>
      <h3>
        •
        <div className="line" />
      </h3>
      <div className="timeline-info">
       <h4>Vice President of Training</h4>
       <h6>EverybodyFights</h6>
       <p>Spearheaded company growth from 1 gym location to over 10, leading trainers and managers across facilities. Developed, managed and refined processes, best practices and strategic growth initiatives.</p>
     </div>
    </div>
    <div className="timeline-item">
      <h5>2012<br />-<br />2016</h5>
      <h3>
        •
        <div className="line second-last" />
      </h3>
      <div className="timeline-info">
       <h4>Research and Teaching Assistant</h4>
       <h6>Tufts University Friedman School of Nutrition Science and Policy</h6>
       <p>Led and participated in data collection and analysis across multiple research projects within the childhood obesity prevention space, while assisting with graduate level courses</p>
     </div>
    </div>
    <div className="timeline-item">
      <h5>2009<br />-<br />2012</h5>
      <h3>
        •
        <div className="line last" />
      </h3>
      <div className="timeline-info">
       <h4>Research Associate</h4>
       <h6>Locomotor Performance Laboratory, Southern Methodist University</h6>
       <p>Developed and led extensive research protocols around human metabolism and movement for the Department of Defense</p>
     </div>
    </div>
    </div>
    </div>
  </>;
}

export default About
